<template>
<div>

  <div class="full-width">
    <div class="container column">
      <h1 class="neue align-center">BENVENUTO DALLA NOSTRA PARTE,<br>SIAMO FIERI DI AVERTI AL NOSTRO FIANCO.</h1>

      <p class="align-center">Diventa subito parte del cambiamento!</p>

      <div class="neue spacedletter align-center">
        <router-link class="button" to="/">Scopri lo shop</router-link>
      </div>

      <div class="container spacer"></div>
    </div>
  </div>

</div>
</template>

<script>
export default {
  name: 'Grazie',

  metaInfo: {
    title: 'Grazie'
  },
}
</script>

<style scoped>
.container.column {
  flex-direction: column;
}

.spacedletter {
  letter-spacing: 0.5px;
}
</style>
